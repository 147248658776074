<div class="qa-translation-overlay" *ngIf="visible">
  <div class="card bg-light mb-3">
    <div class="card-header">
      <i class="fa fa-flag"></i> {{_(KEYS.EDITOR.AUTOMATIC_QUESTIONNAIRE_TRANSLATION)}}
      <span *ngIf="charsToTranslate" class="badge badge-secondary ml-1">{{charsToTranslate}} {{_(KEYS.EDITOR.CHARS)}} | {{estimatePrice}} €</span>

      <button class="close" (click)="hide()">
        <i class="fa fa-close"></i>
      </button>
    </div>
    <div class="card-body">
      <div *ngIf="isTranslating" class="progress mb-3">
        <div
          class="progress-bar"
          role="progressbar"
          [style.width.%]="translationProgress"
          [attr.aria-valuenow]="translationProgress"
          aria-valuemin="0"
          aria-valuemax="100"
        >
        </div>
        <span class="progress-number">{{ translationProgress }}%</span>
      </div>

      <div class="language-list">
        <div class="language-item" *ngFor="let code of objectKeys(languages)">
          <label>
            <input
              type="checkbox"
              [checked]="selectedLanguages.includes(code)"
              (change)="toggleLanguage(code)"
              [disabled]="isTranslating || code === 'de_DE'"
            />
            &nbsp;
            <span class="flag-icon flag-icon-{{getFlagCode(code)}}"></span>
            {{translateLanguage(languages[code])}}
          </label>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button class="btn btn-success" (click)="translateQuestionnaire()" [disabled]="isTranslating">
        <i class="fa fa-upload"></i> {{_(KEYS.EDITOR.TRANSLATE_QUESTIONNAIRE)}}
      </button>
      <button class="btn btn-secondary ml-1" (click)="hide()">
        <i class="fa fa-close"></i> {{_(KEYS.EDITOR.CLOSE)}}
      </button>
    </div>
  </div>
</div>
